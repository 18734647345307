import { UserTitleList, UserTypeList } from "./types";

export const checkUserType = (userType: string) => {
  if (userType === "Supplier" || userType === "Guest") {
    userType = UserTypeList.Supplier;
  } else if (userType === "unsupported") {
    userType = UserTypeList.Guest;
  } else {
    userType = UserTypeList.Member;
  }
  return userType;
};

export const checkLocaleChangeWithUserType = (userType: string) => {
  let locale = "en-US";
  if (userType !== UserTypeList.Member) {
    locale = navigator.language ?? "en-US";
  }
  return locale;
};

export const getLocalewithUserTypeCheck = () => {
  let locale = "en-US";
  const homeBtn = document.getElementsByClassName("home-button")[0];
  if (homeBtn) {
    const userType = homeBtn.getAttribute("data-value");
    if (userType && userType !== UserTypeList.Member) {
      locale = navigator.language ?? "en-US";
    }
  }

  return locale;
};

export const getUserTitleWithUserTypeAndLocale = (
  userType: string,
  locale: string
) => {
  let btitle = UserTitleList.employee;

  switch (userType) {
    case UserTypeList.Member:
      if (locale === "de") {
        btitle = UserTitleList.employeede;
        break;
      }
      btitle = UserTitleList.employee;
      break;
    case UserTypeList.Supplier:
      if (locale === "de") {
        btitle = UserTitleList.supplierde;
        break;
      }
      btitle = UserTitleList.supplier;
      break;
    case UserTypeList.Guest:
      if (locale === "de") {
        btitle = UserTitleList.guestde;
        break;
      }
      btitle = UserTitleList.guest;
      break;
    default:
      btitle = UserTitleList.employee;
  }
  return btitle;
};
