import React, { useEffect } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { msalConfig } from "../auth/auth.config";
import { SignIn } from "../auth/SignIn";
import AzureAdToken from "./AzureAdToken";
import { useAtom } from "jotai";
import { reactTokenAtom, userInfoAtom } from "../atoms";

//if we are on a browser, show SSO login

type AppProps = {
  children: React.ReactNode;
  userInfoLoader: (
    setReactToken: (token: string) => void,
    setUserInfo: (data: any) => void
  ) => void;
  isSSORequired: boolean;
};

const AuthWrapper = ({ children, userInfoLoader, isSSORequired }: AppProps) => {
  const [reactToken, setReactToken] = useAtom(reactTokenAtom);
  const [userInfo, setUserInfo] = useAtom(userInfoAtom);
  useEffect(() => {
    if (!isSSORequired) {
      userInfoLoader(setReactToken, setUserInfo);
    }
  }, []);
  if (!isSSORequired) {
    return <>{userInfo && children}</>;
  } else {
    const msalInstance = new PublicClientApplication(msalConfig);
    return (
      <MsalProvider instance={msalInstance}>
        <>
          <AuthenticatedTemplate>
            <AzureAdToken />
            {userInfo && children}
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <SignIn />
          </UnauthenticatedTemplate>
        </>
      </MsalProvider>
    );
  }
};

export default AuthWrapper;
