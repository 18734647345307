import React, { useEffect, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { useAtom } from "jotai";
import { userInfoAtom } from "../atoms";

const API_URL = process.env.REACT_APP_API_URL;
const AZURE_AD_APP_SCOPE = process.env.REACT_APP_AZURE_AD_APP_SCOPE;

const AzureAdToken = () => {
  const isInitialized = useRef(true);

  const { instance, accounts } = useMsal();
  const [userInfo, setUserInfo] = useAtom(userInfoAtom);

  useEffect(() => {
    const getUserInfo = async () => {
      let accessPage =
        window.location.href.indexOf("supplier") > 0
          ? "supplier"
          : window.location.href.indexOf("guest") > 0
          ? "guest"
          : "";

      const firstAccount = instance.getActiveAccount() || accounts[0];
      const tokenResponse = await instance.acquireTokenSilent({
        scopes: [AZURE_AD_APP_SCOPE || ""],
        account: firstAccount!,
      });

      let reactUserInfoResponse = await axios.get(
        `${API_URL}/token/get-react-token?userpage=${accessPage}`,
        {
          headers: {
            accept: `*/*`,
            "Content-Type": "application/json",
            authorization: `bearer ${tokenResponse.accessToken}`,
          },
        }
      );

      setUserInfo(reactUserInfoResponse.data);
    };

    if (isInitialized.current) {
      isInitialized.current = false;
      getUserInfo();
    }
  }, []);

  return <></>;
};

export default AzureAdToken;
