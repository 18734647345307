export type UserData = {
  conversationId: string;
  username: string;
  chatStarted: boolean;
  botTitle: string;
  minimized: boolean;
  userType: string;
  userLocale: string;
  refUserId: string;
};

export type InputUserData = {
  email: string;
  country: string;
  isguest: boolean;
  vendorNo: string;
  locationCode: string;
  locale: string;
};
export type DirectLineApiResponse = {
  conversationId: string;
  username: string;
  token: string;
  refUserId: string;
  userType: string;
  userLocale: string;
};

export type BotConfigurationResponse = {
  key: string,
  description:string
}

export type MarginType = {
  top: number;
  left: number;
  bottom: number;
  right: number;
};

export enum UserTitleList {
  guest = "Guest Virtual Assistant",
  supplier = "Supplier Virtual Assistant",
  employee = "myProcurement Virtual Assistant",
  guestde = "Virtueller Assistent f\u00fcr Besucher",
  supplierde = "Virtueller Assistent f\u00fcr Lieferanten",
  employeede = "Virtueller Assistent von myProcurement",
}

export enum UserTypeList {
  Member = "Member",
  Supplier = "Supplier",
  Guest = "Guest",
}
