import axios from "axios";
import { DirectLineApiResponse, UserData, InputUserData, BotConfigurationResponse } from "./types";
import { List } from "lodash";
const API_URL = process.env.REACT_APP_API_URL; //URL from env

export const fetchDirectLineTokenAsync = async (userDetails: any) => {
  
  if (!userDetails?.isGuest && !userDetails?.email) {
    throw new Error("invalid email");
  }

  let apiResponse = await axios.post<DirectLineApiResponse>(
    `${API_URL}/token`,
    {
      email: userDetails?.email,
      country: userDetails?.country,
      isguest: userDetails?.isGuest,
      vendorNo: userDetails?.vendorNo,
      locationCode: userDetails?.locationCode,
      locale: navigator.language ?? "en-US",
    },
    {
      headers: {
        accept: `application/json`,
        "Content-Type": "application/json",
      },
    }
  );
  return apiResponse.data;
};
export const fetchReconnectTokenAsync = async (
  directLineToken: string,
  userData: UserData
) => {
  
  const response = await axios.post<DirectLineApiResponse>(
    `${API_URL}/token/reconnect`,
    {
      token: directLineToken,
      conversationId: userData.conversationId,
      username: userData.username,
      refUserId: userData.refUserId,
    },
    {
      headers: {
        accept: `application/json`,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};


export const updateChatStateAsync = async (
  userId: string
) => {
  
  try {
    await axios.put(
      `${API_URL}/token/chat-state/${userId}`,
      {},
      {
        headers: {
          accept: `application/json`,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    console.log(error);
  }
};

export const fetchNotificationMessage = async (
  botUserType: string,
  locale: string
) => {
  
  const response = await axios.post<List<BotConfigurationResponse>>(
    `${API_URL}/BotConfig`,
    {
      key: "Bot-Update-Notification-Message-" + botUserType,
      locale: locale,
    },
    {
      headers: {
        accept: `application/json`,
        "Content-Type": "application/json",
      },
    }
  );
  return response?.data;
};