import React from "react";
import { useEffect, useState, useCallback } from "react";
import { useAtom } from "jotai";
import {
  botLoadingAtom as botLoadedAtom,
  minimizeBotAtom,
  botTitleAtom,
  botLocaleAtom,
  playMessageSoundAtom,
  showEndChatButtonAtom,
  agentQueuePositionAtom,
  queueAttemptAtom,
  queuePositionFromSFAtom,
  notificationAtom,
  checkAtom,
  initateshowNotificationMessageAtom,
  hideEndChatButtonAtom,
  botUserTypeAtom,
} from "../atoms";
import "./PortalChatBox.scss";
import { EnableEndChatBtnIfAlreadyExist,EnableEndChatHideLogic } from '../EndChatButton';
import classNames from "classnames";
import { useCookies } from "react-cookie";
import { getCookieOptions } from "./App";
import DraggableCore from "react-draggable";
import { fetchNotificationMessage, updateChatStateAsync } from "../api";
import { checkLocaleChangeWithUserType } from "../user";
const USER_DATA_COOKIE = "userData";
const NOTIFICATION_MESSAGE_COOKIE = "notificationUpdateMessage";

type ChatBoxProps = {
  store: any;
  className?: string;
  children: React.ReactNode;
};
let HOST_URL = process.env.REACT_APP_HOST_URL;

export const PortalChatBox = ({
  store,
  className,
  children,
}: ChatBoxProps) => {
  const [cookies, setBpChatBotCookie] = useCookies([USER_DATA_COOKIE,NOTIFICATION_MESSAGE_COOKIE]);
  const [positionState, setPositionState] = useState({ position: { x: 0, y: 0 } });
  const [chatBubbleAnimation, setChatBubbleAnimation] = useState(false);
  const [onHowerBubble, setOnHowerBubble] = useState(false);
  const [newNotificationMessage, setNewNotificationMessage] = useState<string|null>(null);
  const [showNewNotificationMessage, setshowNewNotificationMessage] = useState(false);
  const [chatStarted, setChatStarted] = useState(false);
  const [newMessageFromAgent, setNewMessageFromAgent] = useState(false);
  const [botUserType, setBotUserType] = useAtom(botUserTypeAtom);
  const [showButtonCount, setShowButtonCount] = useState(0);
  const [endButtonCount, setEndButtonCount] = useState(0);
  const [endButtonFlag, setEndButtonFlag] = useAtom(hideEndChatButtonAtom);
  const [botLoaded, setBotLoaded] = useAtom(botLoadedAtom);
  const [minimized, setMinimized] = useAtom(minimizeBotAtom);
  const [botTitle] = useAtom(botTitleAtom);
  const [botLocale] = useAtom(botLocaleAtom);
  const [showButtonFlag, setShowButtonFlag] = useAtom(showEndChatButtonAtom);
  const [playMessageSound, setPlayMessageSound] = useAtom(playMessageSoundAtom);
  const [agentQueuePosition,setAgentQueuePosition] = useAtom(agentQueuePositionAtom);
  const [queueAttempt, setQueueAttempt] = useAtom(queueAttemptAtom);
  const [queuePositionFromSF, setQueuePositionFromSF] = useAtom(queuePositionFromSFAtom);
  const [notification, setNotification] = useAtom(notificationAtom);
  const [check, setCheck] = useAtom(checkAtom);
  const [initateshowNotificationMessage, setinitateshowNotificationMessage] =
    useAtom(initateshowNotificationMessageAtom);
  
  const {notificationUpdateMessage :cookiesChatBotNotificationMessage ,userData:cookiesChatBotUserData} = cookies;

  
  const handleMinimizeButtonClick = async () => {
    setPositionState({ position: { x: 0, y: 0 } });
    setMinimized(true);
    setOnHowerBubble(false);
    setshowNewNotificationMessage(false);
    setNewMessageFromAgent(false);
    const userData = cookies.userData ?? {};
    userData.minimized = true;
    setBpChatBotCookie(USER_DATA_COOKIE, userData, getCookieOptions());
  };

  const handleHomeButtonClick = async () => {
    var HomeText = botLocale == "de" ? "Startseite" : "Home";
    store.dispatch({
      type: "WEB_CHAT/SEND_MESSAGE",
      payload: {
        method: "keyboard",
        text: HomeText,
      },
    })
  };

  const handleMaximizeButtonClick = async () => {
    setMinimized(false);
    const userData = cookies.userData ?? {};
    if (!chatStarted) {
      if (!userData.chatStarted) {
        updateChatStateAsync(userData.user);
        userData.chatStarted = true;
        setBpChatBotCookie(USER_DATA_COOKIE, userData, getCookieOptions());
        setChatStarted(userData.chatStarted);
      } else {
        setChatStarted(true);
      }
    }
    userData.minimized = false;
    setBpChatBotCookie(USER_DATA_COOKIE, userData, getCookieOptions());
  
};

  useEffect(() => {    
    if (playMessageSound) {
      let bpChatBotrootElement = document.getElementById("bpchatbootroot");
      let audioElement = document.createElement("audio");
      audioElement.id = "bp-sound-element";
      let sr = document.createElement("source");
      sr.src = HOST_URL + "assets/notify-ping.mp3";
      sr.type = "audio/mpeg";
      audioElement.appendChild(sr);
      bpChatBotrootElement?.appendChild(audioElement);
      setPlayMessageSound(false);
    }
  }, [playMessageSound]);


  const onStop = (event: any, data: any) => {
    const documentElement = document.documentElement;
    const wrapperHeight = window.innerHeight || documentElement.clientHeight;
    const wrapperWidth = window.innerWidth || documentElement.clientWidth;

    const center = {
      x: data.x + data.node.clientWidth / 2,
      y: data.y + data.node.clientHeight / 2,
    };

    const margin = {
      top: center.y - 0,
      left: wrapperWidth / 2 - center.x,
      bottom: wrapperHeight - center.y,
      right: wrapperWidth / 2 + center.x,
    } as any;

  
    const position = {
      top: { y: 0, x: data.x },
      left: { y: data.y, x: 0 },
      bottom: { y: wrapperHeight - data.node.clientHeight, x: data.x },
      right: { y: data.y, x: data.node.clientWidth / 2 + data.x },
    } as any;

    const sorted = Object.keys(margin).sort((a, b) => margin[a] - margin[b]);
    const nearestSide = sorted[0];
    let positionValue = { position: position[nearestSide] };
    if (
      nearestSide === "bottom" &&
      process.env.REACT_APP_API_REDIRECT_URL?.indexOf(window.location.host) ==
        -1
    ) {
      positionValue = { position: { y: 0, x: data.x } };
    }
    if (nearestSide === "right") {
      if (position.right.x < 0 && position.right.y < 0) {
        positionValue = { position: position.top };
      }

      if (position.right.x < 0 && position.right.y > wrapperHeight / 3) {
        positionValue = { position: position.top };
      }
    }
    setPositionState(positionValue);
  };

  const handleOnEnterButton = useCallback(async () => {
    setOnHowerBubble(true);
    setshowNewNotificationMessage(true);
  }, [onHowerBubble, showNewNotificationMessage]);

  const handleOnLeaveButton = useCallback(async () => {
    setOnHowerBubble(false);
    setshowNewNotificationMessage(false);
  }, [onHowerBubble]);

  useEffect(() => {

    let prevMessage = cookiesChatBotNotificationMessage ?? null;
    if (prevMessage && prevMessage !== "" && onHowerBubble) {
      let botmessagearea = document.getElementsByClassName("bot-msg-content");
      if (botmessagearea && botmessagearea[0]) {
        botmessagearea[0].innerHTML = prevMessage;
      }
    } else {
      setOnHowerBubble(false);
      setshowNewNotificationMessage(false);
    }
  }, [onHowerBubble, cookiesChatBotNotificationMessage]);

  useEffect(() => {
    if (initateshowNotificationMessage) {
      const userData = cookiesChatBotUserData ?? {};
      if (userData.userType) {
        getNotificationMessage(userData.userType);
      }
    }
    setinitateshowNotificationMessage(false);
  }, [initateshowNotificationMessage]);

  const getNotificationMessage = useCallback(
    
    async (userType: string) => {

      let locale = checkLocaleChangeWithUserType(userType);
      const response = await fetchNotificationMessage(userType, locale);
      const description = response[0]?.description;
      if (
        description!==undefined && description !== ""
      ) {
        setNewNotificationMessage(description);

        setBpChatBotCookie(
          NOTIFICATION_MESSAGE_COOKIE,
          response[0].description, getCookieOptions()
        );
      }
     
      },[ 
      newNotificationMessage,
      showNewNotificationMessage
      ]);

  useEffect(() => {
    if (notification) {
      var playsound = document.getElementById("bp-sound-element") as HTMLMediaElement;
      if (playsound) {
        playsound.play();
      }
      setNotification(false);
      
      const cookiesUserData = cookiesChatBotUserData ?? {};
      const isMinimized = cookiesUserData.minimized;
      if (isMinimized === null || isMinimized === true) {
        setNewMessageFromAgent(true);
      }
    }
  }, [notification]);


  useEffect(() => {
    if (endButtonFlag) {
      const endAttemptCount = endButtonCount + 1;
      setEndButtonCount(endAttemptCount);
      setEndButtonFlag(false);
    }
  }, [endButtonCount, endButtonFlag]);

  useEffect(() => {
    
    if (showButtonFlag) {
      const showAttemptCount = showButtonCount + 1;
      setShowButtonCount(showAttemptCount);
      setShowButtonFlag(false);
    }
  }, [showButtonFlag,showButtonCount]);

  useEffect(() => {
    
    if (showButtonCount > endButtonCount) {
      if (document.querySelector(".webChat_end-button")) {
        EnableEndChatBtnIfAlreadyExist();
      }
    } else if (showButtonCount < endButtonCount || showButtonCount == endButtonCount) {
      EnableEndChatHideLogic();
    }
    if (check) {
      setCheck(false);
    };
  }, [check,endButtonCount,showButtonCount]);

  useEffect(() => {
    
    if (agentQueuePosition) {
      const queueAttemptCount =queueAttempt  + 1;

      if (queuePositionFromSF) {
        let queuePositionDiv = document.querySelector(
          `${"#Queue_Position_" + queuePositionFromSF.uid}`
        );
        const queuePositionParentNode = (queuePositionDiv?.parentNode as HTMLElement);
        queuePositionParentNode?.classList.add("markdown");
        if (queueAttemptCount <= 2 && queuePositionDiv) {
          const queuep = queuePositionDiv.querySelector("p");
          if (queuep) {
            var innertext =
              botLocale == "de"
                ? `${
                    "Ihre Position in der Warteschleife ist " +
                queuePositionFromSF.qpos
                  }`
                : `${"Your position in queue is " + queuePositionFromSF.qpos}`;
            queuep.innerText = innertext;
          }
        }
      }
      setQueueAttempt(queueAttemptCount);
      setAgentQueuePosition(false);
    }
  }, [    
    queuePositionFromSF,
    agentQueuePosition,
    queueAttempt,
    botLocale,
  ]);

  useEffect(() => {
    
    if (!botLoaded) {
      setInterval(() => {
        setinitateshowNotificationMessage(true);
      }, 2 * 60 * 60 * 1000);
    }
   },[]);

  useEffect(()=>{

    if (chatStarted === true) {
      setChatBubbleAnimation(false);
    }  else {
      setTimeout(() => {
          setChatBubbleAnimation(true);
      }, 2500);

      setTimeout(() => {
          setChatBubbleAnimation(false);
      }, 5000);
    }

  },[botLoaded]);
  
  


  const nodeRef = React.useRef(null);
  return (
    <div
      className={classNames("minimizable-web-chat")}
      data-testid="web-chat-box"
    >
      {minimized && (
        <button
          onMouseEnter={handleOnEnterButton}
          onMouseLeave={handleOnLeaveButton}
          data-testid="bubble-button"
          className={
            classNames("maximize") &&
            classNames("container-bubble", { sample: chatBubbleAnimation })
          }
          onClick={handleMaximizeButtonClick}
        >
          {showNewNotificationMessage && onHowerBubble && (
            <div id="bot-notification">
              <div className="bot-msg-bubble">
                <div className="bot-msg-title">
                  {botLocale == "de" ? "Neuigkeiten!" : "What's new!"}
                </div>
                <div className="bot-msg-content"></div>
              </div>
            </div>
          )}

          <div
            className={classNames(botLocale == "de" ? "image-de" : "image")}
            id="img"
          >
            {newMessageFromAgent && (
              <span className="circle">
                <div data-title=".dot-flashing">
                  <div className="stage">
                    <div className="dot-flashing"></div>
                  </div>
                </div>
              </span>
            )}
          </div>
        </button>
      )}

      {botLoaded && (
        <DraggableCore
          nodeRef={nodeRef}
          key="drgchatBotID"
          handle=".filler"
          position={positionState.position}
          onStop={(event, data) => onStop(event, data)}
        >
          <div
            ref={nodeRef}
            data-testid="chat-box"
            className={classNames(
               "chat-box right",
              minimized ? "hide" : ""
            )}
          >
            <header data-testid="chat-header">
              <div className="filler" data-testid="chat-title">
                <span className="bot-title">{botTitle}</span>
              </div>
              <button
                data-value={botUserType}
                data-testid="chat-home-btn"
                className="home-button"
                // toggle minimized state
                onClick={handleHomeButtonClick}
              >
                <span className="home-icon-button" />
              </button>

              <button
                data-testid="chat-minimize-btn"
                className="minimize"
                // toggle minimized state
                onClick={handleMinimizeButtonClick}
              >
                <span className="ms-Icon ms-Icon--ChromeMinimize" />
              </button>
            </header>
            {children}
          </div>
        </DraggableCore>
      )}
    </div>
  );
};

export default PortalChatBox;
