import { get } from "lodash";

declare global {
  interface Window {
    userInfo: any;
  }
}

export const isBPmeSSORequired = () => {
  if (window.botType === "guest") {
    return false;
  }
  const iosHandler = get(window, `webkit.messageHandlers.bpme`, null);
  const androidHandler = get(window, "bpmeMessageHandler", null);

  return !(iosHandler || androidHandler);
};

export const isPortalSSORequired = () => {
  const userInfoScript = document.scripts.namedItem("userInfo");
  const userInfoData =
    userInfoScript != null ? JSON.parse(userInfoScript.text) : {};

  if (userInfoData && userInfoData.isGuest) {
    return false;
  }

  const HOST_PORTAL_URL = process.env.REACT_APP_API_HOST_PORTAL_URL?.split(",");
  if (HOST_PORTAL_URL && HOST_PORTAL_URL.indexOf(window.location.host) > -1) {
    return false;
  }

  return true;
};
