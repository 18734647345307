import { getLocalewithUserTypeCheck } from  './user';

export const EnableEndChatBtnIfAlreadyExist = async () => {
    const webEndChatEnBtn = document.querySelector(".webChat_end-button") as HTMLElement;
    webEndChatEnBtn.style.display = "block";
  };
  
  export const EnableEndChatBtnIfNotExist = async () => {
    const locale = getLocalewithUserTypeCheck();
    let endChatDiv = document.createElement("div");
    let endChatBtn = document.createElement("button");
    endChatBtn.className = "webChat_end-button";
    endChatBtn.innerText = locale == "de" ? "Chat beenden" : "End Chat";
    endChatDiv.appendChild(endChatBtn);
    const webChatMainDiv = document.getElementsByClassName(
      "webchat__send-box__main"
    )?.[0] as HTMLElement;
    webChatMainDiv.parentNode?.insertBefore(endChatDiv, webChatMainDiv);
  };
  
  export const EnableEndChatHideLogic = () => {
    if (document.querySelector(".home-button")) {
      const homeEnBtn = document.querySelector(".home-button")  as HTMLButtonElement;
      homeEnBtn.style.display = "block";
    }
    if (document.querySelector(".webChat_end-button")) {
      const webEndChatEnBtn = document.querySelector(".webChat_end-button") as HTMLElement;
      webEndChatEnBtn.style.display = "none";
    }
  };
  
  
  export const DiasbleLoadingState = () => {
    let botWebLoading = document.getElementsByClassName("bot-web-loading")?.[0];
    if (botWebLoading) {
      botWebLoading.remove();
    }
  
    let submitButton = document.querySelectorAll('[aria-label="Submit"]');
    var submitButtonLength = submitButton.length;
    let caseSubmitButton = submitButton[submitButtonLength - 1] as HTMLButtonElement;
    if (caseSubmitButton && caseSubmitButton.parentElement) {
      caseSubmitButton.parentElement.style.flexDirection = "column";
    }
  
    let absendenButton = document.querySelectorAll('[aria-label="Absenden"]');
    var absendenButtonLength = absendenButton.length;
    let caseAbsendenButton = absendenButton[absendenButtonLength - 1];
    if (caseAbsendenButton && caseAbsendenButton.parentElement) {
      caseAbsendenButton.parentElement.style.flexDirection = "column";
    }
  };