import { atom } from "jotai";
import { UserData, UserTitleList } from "./types";
import { storeStateUpdatetype } from "./Components/store";

//const USE_CHAT_BUBBLE = process.env.REACT_APP_USE_CHAT_BUBBLE === "true";
declare global {
  interface Window {
    botType: string;
  }
}
//--------------------------------------------------------------- !USE_CHAT_BUBBLE ? true:

export const showBotAtom = atom<boolean>(false);
export const reactTokenAtom = atom<string | null>(null);
export const userInfoAtom = atom<any | null>(null);
export const minimizeBotAtom = atom<boolean>(true);
export const directLineTokenAtom = atom<string | null>(null);
export const showAnimationAtom = atom<boolean>(false);
export const userDataAtom = atom<UserData>({
  conversationId: "",
  minimized: true,
  username: "",
  refUserId: "",
  chatStarted: false,
  botTitle: "",
  userType: "",
  userLocale: "en-US",
});
export const botLoadingAtom = atom<boolean>(false);
export const botTitleAtom = atom<string>(UserTitleList.employee);
export const botLocaleAtom = atom<string>("en-US");
export const playMessageSoundAtom = atom<boolean>(false);
export const showEndChatButtonAtom = atom<boolean>(false);
export const hideEndChatButtonAtom = atom<boolean>(false);
export const queuePositionFromSFAtom = atom<{qpos:string,uid:string}>({qpos:"",uid:""});
export const agentQueuePositionAtom = atom<boolean>(false);
export const queueAttemptAtom = atom<number>(0);
export const notificationAtom = atom<boolean>(false);
export const windowLocaleAtom = atom<string>("en-US");
export const checkAtom = atom<boolean>(false);
export const initateshowNotificationMessageAtom = atom<boolean>(false);
export const botUserTypeAtom = atom<string>("Member");;