import React, { useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "./auth.config";

export const SignIn = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.error(e);
      });
    }
  }, [isAuthenticated]);

  return <div>Please Sign In</div>;
};

export default SignIn;