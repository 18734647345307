import "./App.scss";
import "../fabric-icons-inline.css";
import { useAtom } from "jotai";
import PortalChatBox from "./PortalChatBox";
import { useEffect, useRef, useState, useCallback } from "react";
import { useCookies } from "react-cookie";
import {
  userDataAtom,
  directLineTokenAtom,
  userInfoAtom,
  botLoadingAtom,
  minimizeBotAtom,
  botTitleAtom,
  playMessageSoundAtom,
  showEndChatButtonAtom,
  agentQueuePositionAtom,
  queueAttemptAtom,
  queuePositionFromSFAtom,
  notificationAtom,
  windowLocaleAtom,
  botLocaleAtom,
  checkAtom,
  initateshowNotificationMessageAtom,
  reactTokenAtom,
  hideEndChatButtonAtom,
  botUserTypeAtom
} from "../atoms";
import { getStore } from "./store";
import WebChat from "./WebChat";
import { fetchDirectLineTokenAsync, fetchNotificationMessage, fetchReconnectTokenAsync } from "../api";
import { UserData, UserTitleList } from "../types";
import {
  checkUserType,
  checkLocaleChangeWithUserType,
  getUserTitleWithUserTypeAndLocale,
} from "../user";
const TOKEN_COOKIE = "token";
const USER_DATA_COOKIE = "userData";
const NOTIFICATION_MESSAGE_COOKIE = "notificationUpdateMessage";
export const getCookieOptions = () => {
  let d = new Date();
  d.setTime(d.getTime() + 8 * 60 * 60 * 1000);
  return {
    path: "/",
    sameSite: true,
    secure: true,
    expires: d,
  };
};

const App = () => {
  const isInitialized = useRef<boolean>(true);
  const botStore = useRef<any>(null);

  const [userData, setUserData] = useAtom(userDataAtom);
  const [directLineToken, setDirectLineToken] = useAtom(directLineTokenAtom);
  const [userName, setUsername] = useState<string>("Guest");
  const [botLocale, setBotLocale] = useAtom(botLocaleAtom);
  const [windowLocale, setWindowLocale] = useAtom(windowLocaleAtom);
  const [userInfo, setUserInfo] = useAtom(userInfoAtom);
  const [loaded, setLoaded] = useAtom(botLoadingAtom);
  const [minimizeBot, setminimizeBot] = useAtom(minimizeBotAtom);
  const [chatBotTitle, setChatBotTitle] = useAtom(botTitleAtom);
  const [initateshowNotificationMessage, setinitateshowNotificationMessage] =
  useAtom(initateshowNotificationMessageAtom);
  const [cookies, setBpChatBotCookie, removeBpChatBotCookie] = useCookies([
    TOKEN_COOKIE,
    USER_DATA_COOKIE,
    NOTIFICATION_MESSAGE_COOKIE
  ]);
  const [playMessageSound, setPlayMessageSound] = useAtom(playMessageSoundAtom);
  const [showButtonFlag, setShowButtonFlag] = useAtom(showEndChatButtonAtom);
  const [endButtonFlag, setEndButtonFlag] = useAtom(hideEndChatButtonAtom);
  const [agentQueuePosition, setAgentQueuePosition] = useAtom(agentQueuePositionAtom);
  const [queueAttempt, setQueueAttempt] = useAtom(queueAttemptAtom);
  const [queuePositionFromSF, setQueuePositionFromSF] = useAtom(queuePositionFromSFAtom);
  const [notification, setNotification] = useAtom(notificationAtom);
  const [check, setCheck] = useAtom(checkAtom);
  const [botUserType, setBotUserType] = useAtom(botUserTypeAtom);
  const createLocalStore = () => {
    const store = () => {
      return getStore({
        setPlayMessageSound, setShowButtonFlag,setEndButtonFlag, setQueuePositionFromSF, setAgentQueuePosition, setQueueAttempt,setNotification,setWindowLocale,setCheck
      });
    };

    botStore.current = store();
  };

  const updateUserDataCookie = (data: any) => {
    setBpChatBotCookie(
      USER_DATA_COOKIE,
      {
        ...cookies.userData,
        ...data,
      },
      getCookieOptions()
    );
  };

  const fetchFreshToken = useCallback(async () => {
    try {
      const tokenResponse = await fetchDirectLineTokenAsync(userInfo);

      //Need to check the userType from the response
      let btitle = UserTitleList.employee;
      let userType = "Member";
      let botLocale = navigator.language ?? "en-US";
      if (tokenResponse.userType) {
        userType = checkUserType(tokenResponse.userType);
        botLocale = checkLocaleChangeWithUserType(userType);
        btitle = getUserTitleWithUserTypeAndLocale(userType, botLocale);
      }
      // //Need to fetch title based on the usertype and locale

      setDirectLineToken(tokenResponse.token);
      const userData = {
        conversationId: tokenResponse.conversationId,
        user: tokenResponse.refUserId,
        username: tokenResponse.username,
        chatStarted: false,
        minimized: true,
        userType: tokenResponse.userType,
        userLocale: botLocale,
        botTitle: btitle,
        refUserId:tokenResponse.refUserId
      } as UserData;
      setChatBotTitle(btitle);
      setBotLocale(botLocale);
      setUserData(userData);
      setBpChatBotCookie(TOKEN_COOKIE, tokenResponse.token, getCookieOptions());
      updateUserDataCookie(userData);
      setUsername(tokenResponse.username);
      setLoaded(true);
      setminimizeBot(true);
      setinitateshowNotificationMessage(true);
      setBotUserType(userData.userType);
      getNotification(userType,botLocale);
    } catch (error) {
      console.log(error);
    }
  }, [userInfo]);

  const getNotification = useCallback(async (notificationBotUserType:string,notificationLocale:string) => {
   
    const resp = await fetchNotificationMessage(notificationBotUserType, notificationLocale);
    const description = resp[0]?.description;
    if (description!==undefined && description) {
      setBpChatBotCookie(
        NOTIFICATION_MESSAGE_COOKIE,
        description, getCookieOptions()
      );      
    }

   },[directLineToken]);

  const fetchReconnectToken = useCallback(async () => {
    try {
      const cookieUserData = cookies.userData;
      const cToken = cookies.token;
      if (cToken && userData) {
        const reactTokenResponse = await fetchReconnectTokenAsync(
          cToken,
          cookieUserData
        );
        setDirectLineToken(reactTokenResponse.token);
        const userData = {
          conversationId: reactTokenResponse.conversationId,
          user: reactTokenResponse.refUserId,
          username: reactTokenResponse.username,
          chatStarted: cookieUserData.chatStarted,
          minimized: cookieUserData.minimized,
          userType: cookieUserData.userType,
          userLocale: cookieUserData.userLocale,
          botTitle: cookieUserData.botTitle,
          refUserId:cookieUserData.refUserId
        } as UserData;

        setUserData(userData);
        setBpChatBotCookie(
          TOKEN_COOKIE,
          reactTokenResponse.token,
          getCookieOptions()
        );
        setBotUserType(userData.userType);
        updateUserDataCookie(userData);
        setUsername(reactTokenResponse.username);
        setLoaded(true);
        setminimizeBot(userData.minimized);
      } else {
        fetchFreshToken();
      }
    } catch (error: any) {
      if (error.response) {
        removeBpChatBotCookie(TOKEN_COOKIE);
        removeBpChatBotCookie(USER_DATA_COOKIE);
      } else if (error.request) {
        console.log(error.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log("Error", error.message);
      }
      fetchFreshToken();
    }
  }, [directLineToken]);

  useEffect(()=>{
    
  },[reactTokenAtom]);

  useEffect(() => {
    if (isInitialized.current) {
      if (cookies.token && cookies.userData) {
        setDirectLineToken(cookies.token);
        setUserData(cookies.userData);
        // if loading from cookies
        isInitialized.current = false;
        fetchReconnectToken();
        createLocalStore();
      } else {
        if (userInfo && !directLineToken) {
          isInitialized.current = false;
          fetchFreshToken();

          createLocalStore();
        }
      }
    }
  }, [directLineToken]);

  return (
    <div data-test-id="app" className="app">
      <PortalChatBox store={botStore.current}>
        {botStore.current && (
          <WebChat
            data-testid="web-chat"
            className="react-web-chat"
            store={botStore.current}
            username={userName}
            botLocale={botLocale}
            windowLocale={windowLocale}
          />
        )}
      </PortalChatBox>
    </div>
  );
};

export default App;
